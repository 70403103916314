<script lang="ts">
import { defineComponent } from 'vue'

/**
 * A toggle element.
 */
export default defineComponent({
  props: {
    /**
     * Name of the toggle element.
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Model prop to v-bind to.
     */
    modelValue: {
      type: Boolean,
      required: true,
    },
    /**
     * The value of the checkbox.
     * If none is set, the value will be "true" or "false" depending on the checked state.
     */
    value: {
      type: String,
    },
    /**
     * Reverse the placement of the toggle to label slot.
     * Default: toggle on left, text on right.
     */
    reverseOrder: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    checked: {
      get() {
        return this.modelValue
      },
      set(checked: boolean) {
        this.$emit('update:modelValue', checked)
      },
    },
  },
})
</script>

<template>
  <label
    class="inline-flex items-center hover:cursor-pointer"
    :class="{ 'flex-row-reverse': reverseOrder }"
  >
    <input v-model="checked" type="checkbox" :name :value="value ?? checked" />

    <span :class="[reverseOrder ? 'mr-2' : 'ml-2']">
      <!-- @slot Used to place content next to the toggle element. -->
      <slot />
    </span>
  </label>
</template>

<style lang="scss" scoped>
input[type='checkbox'] {
  appearance: none;
  position: relative;
  display: inline-block;
  background: lightgray;
  height: 24px;
  width: 49px;
  border-radius: 100px;
  box-shadow: 0 1px 3px #0003 inset;
  transition: 0.25s linear background;

  &:checked {
    @apply bg-primary;

    &::before {
      transform: translateX(1.56rem);
    }
  }

  &:not(:checked) {
    border-color: lightgray;
  }

  &:focus-visible {
    outline: 2px solid #0003;
    outline-offset: 2px;
  }

  &:focus {
    outline: none;
    outline-color: transparent;
  }

  &::before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 100%;
    position: absolute;
    top: 1px;
    box-shadow: 0 1px 3px #0003;
    transition: 0.25s linear transform;
    transform: translateX(0.15rem);
  }
}
</style>
