<script lang="ts">
import { useCountdown } from '@/plugins/useCountdown'
import { type DateTime } from 'luxon'
import { type PropType, defineComponent } from 'vue'

/**
 * A count-down timer display.
 */
export default defineComponent({
  props: {
    /**
     * The ending datetime to countdown to.
     * Must be of type (Luxon) DateTime.
     */
    datetime: {
      type: Object as PropType<DateTime>,
      required: true,
    },
    displayRelative: {
      type: Boolean,
      default: true,
    },
    background: {
      type: String,
      default: 'bg-primary',
    },
    endCallback: {
      type: Function,
    },
  },
  data() {
    return {
      countdown: useCountdown(this.endCallback),
    }
  },
  mounted() {
    this.countdown.start(this.datetime)
  },
  beforeUnmount() {
    this.countdown.end()
  },
  // TODO Not performant!!
  beforeUpdate() {
    this.countdown.start(this.datetime)
  },
})
</script>

<template>
  <div
    class="countdown flex items-center justify-center px-2 py-3 text-pure-white md:px-4"
    :class="background"
  >
    <time
      v-if="countdown.showAsDate"
      :datetime="datetime.toString()"
      class="relative-date flex items-center justify-center text-xl font-semibold leading-none will-change-contents"
    >
      {{ $d(datetime.toJSDate(), 'long') }}
    </time>

    <time
      v-if="!countdown.showAsDate"
      :datetime="datetime.toString()"
      class="flex w-full justify-around gap-1 will-change-contents"
    >
      <div
        v-if="Number(countdown.remainingDateTimeFormatted.days) > 0"
        class="time-segment"
      >
        <span class="days time-left">
          {{ countdown.remainingDateTimeFormatted.days }}
        </span>
        <span class="time-description">
          {{ $t('countdown.day', countdown.remainingDateTime.days) }}
        </span>
        <span class="time-description time-description-char">
          {{ $t('countdown.d', countdown.remainingDateTime.days) }}
        </span>
      </div>

      <div class="time-segment">
        <span class="hours time-left">
          {{ countdown.remainingDateTimeFormatted.hours }}
        </span>
        <span class="time-description">
          {{ $t('countdown.hr', countdown.remainingDateTime.hours) }}
        </span>
        <span class="time-description time-description-char">
          {{ $t('countdown.h', countdown.remainingDateTime.hours) }}
        </span>
      </div>

      <div class="time-segment">
        <span class="minutes time-left">
          {{ countdown.remainingDateTimeFormatted.minutes }}
        </span>
        <span class="time-description">
          {{ $t('countdown.min', countdown.remainingDateTime.minutes) }}
        </span>
        <span class="time-description time-description-char">
          {{ $t('countdown.m', countdown.remainingDateTime.minutes) }}
        </span>
      </div>

      <div class="time-segment-seconds time-segment">
        <span class="seconds time-left">
          {{ countdown.remainingDateTimeFormatted.seconds }}
        </span>
        <span class="time-description">
          {{ $t('countdown.sec', countdown.remainingDateTime.seconds) }}
        </span>
        <span class="time-description time-description-char">
          {{ $t('countdown.s', countdown.remainingDateTime.seconds) }}
        </span>
      </div>
    </time>
  </div>
</template>

<style lang="scss" scoped>
.time-segment {
  @apply flex flex-shrink items-baseline;

  .time-left {
    @apply text-xl font-semibold leading-none;
  }

  .time-description {
    @apply min-w-[2ch] text-xs font-semibold uppercase;

    &.time-description-char {
      @apply hidden;
    }
  }
}

.countdown {
  container-type: inline-size;

  @container (max-width: 18ch) {
    .time-segment-seconds {
      @apply hidden;
    }
  }

  @container (max-width: 23ch) {
    .time-description:not(.time-description-char) {
      @apply hidden;
    }

    .time-description.time-description-char:not(.seconds) {
      @apply inline-block;
    }
  }
}
</style>
