import type {
  InstantWinDrawEntry,
  InstantWinDrawTickets,
  InstantWinWinnerEntries,
  InstantWinWinnerEntriesWithReference,
  TPlanTier,
} from '@/types'
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber'
import { DateTime } from 'luxon'

import { InstantWinPrizeType, PlanTiers } from './helper-objects'
import { isE164PhoneNumber } from './input-validator-utils'

/**
 * Compares two values of type string, number, or DateTime.
 *
 * If both values are DateTime objects, the difference in milliseconds is returned.
 * Otherwise, the values are compared as strings using localeCompare with 'en-GB' locale, numeric usage, and base sensitivity (case-insensitive).
 *
 * @template T - The type of the values to compare. Defaults to string, number, or DateTime.
 * @param {T} v1 - The first value to compare.
 * @param {T} v2 - The second value to compare.
 * @returns {number} - Returns a negative number if v1 is less than v2, zero if they are equal, or a positive number if v1 is greater than v2.
 */
export const compareFn = <T = string | number | DateTime>(
  v1: T,
  v2: T
): number => {
  if (DateTime.isDateTime(v1) || DateTime.isDateTime(v2)) {
    const v1Datetime = DateTime.isDateTime(v1) ? v1 : DateTime.fromISO(`${v1}`)
    const v2Datetime = DateTime.isDateTime(v2) ? v2 : DateTime.fromISO(`${v2}`)

    if (!v1Datetime.isValid || !v2Datetime.isValid) {
      throw new Error('Invalid DateTime value')
    }

    return v2Datetime.diff(v1Datetime).milliseconds === 0
      ? 0
      : v2Datetime.diff(v1Datetime).milliseconds < 0
        ? 1
        : -1
  }

  return `${v1}`.localeCompare(`${v2}`, new Intl.Locale('en-GB'), {
    usage: 'search',
    numeric: true,
    sensitivity: 'base',
  })
}

/**
 * Flips an object's values for it's keys (both must be strings).
 *
 * @param {Record<string | number, string | number>} obj
 * @returns {Record<string | number, string | number>}
 */
export const flipObject = (
  obj: Record<string | number, string | number>
): Record<string | number, string | number> => {
  return Object.fromEntries(Object.entries(obj).map((o) => o.reverse()))
}

/**
 * Groups an array of items by a key callback.
 *
 * @param {(item: T) => string} keyFn
 * @param {Array<T>} items
 * @returns {Record<string, T[]>}
 */
export const groupBy = <T>(
  keyFn: (item: T) => string | number,
  items: Array<T>
): Record<string, T[]> => {
  const groupMap = new Map()

  for (const item of items) {
    const key = keyFn(item)

    const group = groupMap.get(key) ?? []

    group.push(item)

    groupMap.set(key, group)
  }

  return Object.fromEntries(groupMap)
}

/**
 * Checks if a value is empty.
 *
 * @param {any} value
 * @returns {boolean}
 */
export const isEmpty = (value: any): boolean => {
  if (value == null || value === false) {
    return true
  } // Checks for null and undefined or false

  if (typeof value === 'string' && value.trim().length === 0) {
    return true
  } // Checks for empty string

  if (Array.isArray(value) && value.length === 0) {
    return true
  } // Checks for empty array

  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return true
  } // Checks for empty object

  return false
}

/**
 * Wraps a value in an array if it is not already an array.
 *
 * @param {T | Array<T>} value - The value to wrap in an array.
 * @returns {Array<T>} - The value wrapped in an array, or the original array if the value is already an array.
 */
export const arrayWrap = <T>(value: T | Array<T>): Array<T> =>
  Array.isArray(value) ? value : [value]

type RecursiveMapType =
  | undefined
  | null
  | number
  | string
  | boolean
  | Array<any>
  | Set<any>
  | Map<any, any>
  | { [key: string]: any }

/**
 * Maps all values in an object according to the callback.
 *
 * @param {Record<string, T>} obj
 * @param {(value: any, key?: string) => any} fn
 * @returns {T}
 */
export const mapRecursive = (
  val: RecursiveMapType,
  fn: (value: any, key?: string | number) => any
): RecursiveMapType => {
  if (val instanceof Map) {
    // val is a map
    const newMap = new Map()

    for (const [key, value] of val) {
      // If the value is a Map, recursively map its values
      if (value instanceof Map) {
        newMap.set(key, mapRecursive(value, fn)) // Recursive call
      } else {
        newMap.set(key, fn(value, key)) // Apply callback to the value
      }
    }

    return newMap
  } else if (val instanceof Set) {
    // val is a set
    return new Set(
      [...val].map((value) => {
        // If the value is a Set itself, recursively map its values
        if (value instanceof Set) {
          return mapRecursive(value, fn)
        }
        // Otherwise, apply the callback to the value
        return fn(value)
      })
    )
  } else if (Array.isArray(val)) {
    // val is an array
    return val.map((value, index) => {
      if (
        value instanceof Set ||
        value instanceof Map ||
        Array.isArray(value) ||
        (typeof value === 'object' && value !== null)
      ) {
        return mapRecursive(value, fn) // Recursion
      } else {
        return fn(value, index)
      }
    })
  } else if (val instanceof Object) {
    // val is an object
    return Object.keys(val).reduce(
      (acc, key) => {
        const value = val[key]

        // If the value is an object, recursively map its values
        if (value && typeof value === 'object') {
          acc[key] = mapRecursive(value, fn) // Recursive call
        } else {
          acc[key] = fn(value, key) // Apply callback to the value
        }

        return acc
      },
      {} as Record<string, any>
    )
  } else {
    // val is a primitive value
    return fn(val)
  }
}

/**
 * Compares plans (by tier) testing if one plan is higher than the other.
 *
 * @param {TPlanTier} planTier
 * @param {TPlanTier} comparativePlanTier
 * @returns {boolean}
 */
export const isAvailableInPlan = (
  planTier: TPlanTier,
  comparativePlanTier: TPlanTier
): boolean => {
  return PlanTiers[planTier] >= PlanTiers[comparativePlanTier]
}

/**
 * Returns the plan tier of the next highest plan.
 *
 * @param {TPlanTier} planTier
 * @returns {TPlanTier}
 */
export const nextAvailablePlan = (planTier: TPlanTier): TPlanTier => {
  const currentTier = PlanTiers[planTier]
  const nextTier = currentTier + 1

  const lastTier = Math.max(...Object.values(PlanTiers))

  if (currentTier >= lastTier) {
    return planTier
  }

  // Get all plans from the next tier
  return Object.entries(PlanTiers).filter(
    ([, tierValue]) => tierValue === nextTier
  )?.[0]?.[0] as TPlanTier
}

/**
 * Formats a phone number to E.164 format.
 * If the phone number is not in E.164 format, it will return the original phone number.
 *
 * @param {string | number} phoneNumber
 * @returns {string}
 */
export const formatToPhoneNumber = (phoneNumber: string | number): string => {
  if (isE164PhoneNumber({ name: 'phone', value: phoneNumber }) === true) {
    const phoneUtil = new PhoneNumberUtil()

    return phoneUtil.format(
      phoneUtil.parse(`${phoneNumber}`, 'GB'),
      PhoneNumberFormat.E164
    )
  }

  return `${phoneNumber}`
}

export const toValue = (value: any | (() => any)): any => {
  if (isEmpty(value)) {
    return value
  }

  return typeof value === 'function' ? value() : value
}

/**
 * Groups all instant draw's winning entries by the draw.
 *
 * @param {Record<string, Array<InstantWinDrawTickets>>} tickets
 * @param {keyof InstantWinWinnerEntries} groupBy
 * @returns {Array<InstantWinDrawEntry>}
 */
export const groupInstantWinWinnersByDraw = (
  tickets: Record<string, Array<InstantWinDrawTickets>>,
  groupBy: keyof InstantWinWinnerEntries = 'draw_reference_id'
): Array<InstantWinDrawEntry> => {
  return [
    ...Object.values(tickets)
      // Flatten all instant draws to winning entries
      .flatMap((instantWinDraw: Array<InstantWinDrawTickets>) => {
        return instantWinDraw
          .flatMap((draw) => draw.winning_entries)
          .filter(
            (drawTickets) =>
              drawTickets.prize_type === InstantWinPrizeType.Entry &&
              drawTickets[groupBy]
          )
      })
      // Group by draw reference id and create a map of draw entries with count
      .reduce((entryMap, entry) => {
        const entryWithReference = entry as InstantWinWinnerEntriesWithReference

        if (!entryMap.has(entryWithReference.draw_reference_id)) {
          entryMap.set(entryWithReference.draw_reference_id, {
            count: 0,
            drawLabel: entryWithReference.prize_name,
            drawnAt: entryWithReference.drawn_at,
            prizeType: entryWithReference.prize_type,
            tickets: [],
          })
        }

        const instantWinDrawEntry = entryMap.get(
          entryWithReference.draw_reference_id
        ) as InstantWinDrawEntry

        instantWinDrawEntry.count += entryWithReference.prize_quantity
        instantWinDrawEntry.tickets.push(entryWithReference)

        return entryMap
      }, new Map<number, InstantWinDrawEntry>())
      .values(),
  ]
}
