import { i18n } from '@/i18n'
import type { FaqAccordionContent, InstantWinDraw, Prize } from '@/types'
import {
  FaqCategories,
  FaqSlugs,
  PrizeCategories,
} from '@/utils/helper-objects'
import { defineStore } from 'pinia'

export const useFaqStore = defineStore('faq', {
  getters: {
    homepageFaq(): FaqAccordionContent {
      return {
        title: '',
        content: [
          {
            title: `What is ${this.BUSINESS_NAME}?`,
            text: `${this.BUSINESS_NAME} is your all-access pass to daily fun and exclusive perks! It's an entertainment-focused membership that automatically enters you into daily prize draws, where you could win incredible rewards like cash, shiny cars, luxury trips, and more.<br>But that's not all—our members also enjoy a range of monthly perks, from free cinema tickets to discounts at top brands and restaurants. The best part? Each membership tier offers its own unique mix of daily entries and benefits, so there's something for everyone.<br>Curious to know more? Check out all the details on our Membership Page and see what you could be a part of! <br> You can find out more detail on our membership page, just visit <a class='link' href='${this.$router.resolve({ name: 'memberships' }).href}' target='_blank'>www.goodlifeplus.co.uk/memberships</a>`,
          },
          {
            title: 'How do I know if I have won? ',
            text: "Winners are announced daily during our live streams. Tune in live or visit the <a href='/live-draws' class='link'>Live Draws</a> section of our website to catch up on any missed draws. If you win, we'll contact you directly via the details you've provided. Don't worry - we'll always make sure you know about your win!",
          },
          {
            title: 'How can I cancel my subscription?',
            text: `Easily! You can cancel your ${this.BUSINESS_NAME} membership at any time. Simply call us on <a href="tel:02046009030" class="link" target="_blank">0204 600 9030</a> during our opening hours of 9.00am to 5.30pm Monday to Thursday and Friday 9.00am to 5.00pm. Please rest assured that our team will always get back to you and resolve whatever issue you may have.`,
          },
          {
            title: 'How do I enter the draws?',
            text: `There are three simple ways to enter a draw:<br><br><strong>Free online entry</strong><br>A one-time free entry for everyone. <br><br><strong>Membership</strong><br>Enjoy multiple entries, exclusive perks, and incredible rewards like dining discounts and free movie tickets — all starting at just ${i18n.global.n(11.99, 'currency')} a month.<br><br><strong>Postal entry</strong><br>Check out the terms and conditions for details on how to enter by post. Once entered, sit back and relax or tune into our live streams to see if you've won!`,
          },
          {
            title: 'Where are you based?',
            text: `We are London based, Our dedicated team is here to support you every step of the way. Whether you have questions about our services or need assistance, you can count on us to provide prompt and helpful responses. <br>To find out more you can contact us via <a class='link' href='mailto:${this.BUSINESS_EMAIL}' target='_blank'>${this.BUSINESS_EMAIL}</a> or give our friendly team a call on <a href="tel:02031461890" class="link" target="_blank">0203 146 1890</a>.`,
          },
          {
            title: 'How do benefits work?',
            text: "Each plan comes with varying benefits which you can see on <a class='link' href='/memberships' target='_blank'>www.goodlifeplus.co.uk/memberships</a>. Your benefits are accessible to you through the memberzone; as long as your membership fee has been paid.",
          },
          {
            title: 'How are the winners chosen?',
            text: "<p>We use <a class='link' href='//randomdraws.co.uk' target='_blank'>RandomDraws.co.uk</a>, a fully certified and independent platform, to select our winners. This guarantees 100% fairness and transparency.</p><br><p>Winners are chosen randomly every day, and the results are announced during our live streams. To keep things fair and compliant, the draw always takes place before the live stream, so we're all set to present the winner on air.</p><br><p>Our process meets the highest standards, including the Promotional Marketing Rule 8.24 of the CAP Code—because fairness is non-negotiable!</p>",
          },
        ],
      }
    },
    campaignFaq(): FaqAccordionContent {
      return {
        title: '',
        content: [
          {
            title: `What is ${this.BUSINESS_NAME}?`,
            text: `${this.BUSINESS_NAME} is an entertainment focussed membership, which provides automatic entry to daily draws, a range of monthly perks ranging from cinema tickets to discounts and deals. Each membership tier comes with its own number of daily entries and perks. <br>You can find out more detail on our membership page, just visit <a class='link' target='_blank' href='/memberships'>www.goodlifeplus.co.uk/memberships</a>`,
          },
          {
            title: 'How do I know if I have won?',
            text: "Winners are announced daily during our live streams. Tune in live or visit the <a href='/live-draws' class='link'>Live Draws</a> section of our website to catch up on any missed draws.<br>If you win, we'll contact you directly via the details you've provided. Don't worry - we'll always make sure you know about your win!",
          },
          {
            title: 'How can I cancel my subscription?',
            text: `Easily! You can cancel your ${this.BUSINESS_NAME} membership at any time. Simply call us on <a href="tel:02046009030" class="link" target="_blank">0204 600 9030</a> during our opening hours of 9.00am to 5.30pm Monday to Thursday and Friday 9.00am to 5.00pm. Please rest assured that our team will always get back to you and resolve whatever issue you may have.`,
          },
          {
            title: 'How do I enter the draws?',
            text: `There are three simple ways to enter a draw:<br><br><strong>Free online entry</strong><br>A one-time free entry for everyone. <br><br><strong>Membership</strong><br>Enjoy multiple entries, exclusive perks, and incredible rewards like dining discounts and free movie tickets — all starting at just ${i18n.global.n(11.99, 'currency')} a month.<br><br><strong>Postal entry</strong><br>Check out the terms and conditions for details on how to enter by post. Once entered, sit back and relax or tune into our live streams to see if you've won!`,
          },
          {
            title: 'Where are you based?',
            text: `We are London based, Our dedicated team is here to support you every step of the way. Whether you have questions about our services or need assistance, you can count on us to provide prompt and helpful responses.<br>To find out more you can contact us via <a class='link' href='mailto:${this.BUSINESS_EMAIL}' target='_blank'>${this.BUSINESS_EMAIL}</a> or give our friendly team a call on <a href="tel:02031461890" class="link" target="_blank">0203 146 1890</a>.`,
          },
          {
            title: 'How do benefits work?',
            text: "Each plan comes with varying benefits which you can see on <a class='link' target='_blank' href='/memberships'>www.goodlifeplus.co.uk/memberships</a>. Your benefits are accessible to you through the memberzone; as long as your membership fee has been paid.",
          },
        ],
      }
    },
    productFaq(): FaqAccordionContent {
      return {
        title: '',
        content: [
          {
            title: `What is ${this.BUSINESS_NAME}?`,
            text: `${this.BUSINESS_NAME} is an entertainment focussed membership, which provides automatic entry to daily draws, a range of monthly perks ranging from cinema tickets to discounts and deals. Each membership tier comes with its own number of daily entries and perks. <br>You can find out more detail on our membership page, just visit <a class='link' target='_blank' href='/memberships'>www.goodlifeplus.co.uk/memberships</a>`,
          },
          {
            title: 'How do I know if I have won?',
            text: "Winners are announced daily during our live streams. Tune in live or visit the <a href='/live-draws' class='link'>Live Draws</a> section of our website to catch up on any missed draws.<br>If you win, we'll contact you directly via the details you've provided. Don't worry - we'll always make sure you know about your win!",
          },
          {
            title: 'How can I cancel my subscription?',
            text: `Easily! You can cancel your ${this.BUSINESS_NAME} membership at any time. Simply call us on <a href="tel:02046009030" class="link" target="_blank">0204 600 9030</a> during our opening hours of 9.00am to 5.30pm Monday to Thursday and Friday 9.00am to 5.00pm. Please rest assured that our team will always get back to you and resolve whatever issue you may have.`,
          },
          {
            title: 'How do I enter the draws?',
            text: `There are three simple ways to enter a draw:<br><br><strong>Free online entry</strong><br>A one-time free entry for everyone. <br><br><strong>Membership</strong><br>Enjoy multiple entries, exclusive perks, and incredible rewards like dining discounts and free movie tickets—all starting at just ${i18n.global.n(11.99, 'currency')} a month.<br><br><strong>Postal entry</strong><br>Check out the terms and conditions for details on how to enter by post. Once entered, sit back and relax or tune into our live streams to see if you've won!`,
          },
          {
            title: 'Where are you based?',
            text: `We are London based, Our dedicated team is here to support you every step of the way. Whether you have questions about our services or need assistance, you can count on us to provide prompt and helpful responses. <br>To find out more you can contact us via <a class='link' href='mailto:${this.BUSINESS_EMAIL}' target='_blank'>${this.BUSINESS_EMAIL}</a> or give our friendly team a call on <a href="tel:02031461890" class="link" target="_blank">0203 146 1890</a>.`,
          },
          {
            title: 'How do benefits work?',
            text: "Each plan comes with varying benefits which you can see on <a class='link' target='_blank' href='/memberships'>www.goodlifeplus.co.uk/memberships</a>. Your benefits are accessible to you through the memberzone; as long as your membership fee has been paid.",
          },
        ],
      }
    },
    benefitFaq(): FaqAccordionContent {
      return {
        title: '',
        content: [
          {
            title: 'How do benefits work?',
            text: "Each plan comes with varying benefits which you can see on <a class='link' target='_blank' href='/memberships'>www.goodlifeplus.co.uk/memberships</a>. Your benefits are accessible to you through the memberzone; as long as your membership fee has been paid.",
          },
          {
            title: 'How can I access my benefits?',
            text: "You can access your benefits from the member zone or the benefits page, here you can claim & access the perks associated with your plan. If a perk isn't available with your specific plan you can always upgrade to get the best possible value out of your membership.",
          },
          {
            title: 'What benefits do I get with my Standard plan?',
            text: 'With your standard plan you are entitled to a TasteCard membership; you can claim this from the benefits page. Tastecard gives you access to tons of discounts and deals ranging from 2-for-1`s to 25% of your next coffee, its easy, fast and saves you a lot of money each month.',
          },
          {
            title: 'What benefits do I get with my Platinum plan?',
            text: 'With your Platinum plan you are entitled to a TasteCard membership; you can claim this from the benefits page. Tastecard gives you access to tons of discounts and deals ranging from 2for1s to 25% of your next coffee, its easy, fast and can save you a lot of money each month. <br>Each and every month, you will also have access to a choice of a Costa, Starbucks, Tesco, Deliveroo, Cafe Nero or M&S voucher or a breakfast deal at Greggs!',
          },
          {
            title: 'What benefits do I get with my VIP plan?',
            text: "With your Platinum plan you are entitled to a TasteCard membership; you can claim this from the benefits page. Tastecard gives you access to tons of discounts and deals ranging from 2for1s to 25% off your next coffee, it's easy, fast and saves you a lot of money each month. <br>Each and every month, you will also have access to a choice of a Costa, Starbucks, Tesco, Deliveroo, Cafe Nero or M&S voucher or a breakfast deal at Greggs!<br>On top of that you will also have access to a choice of two Vue Cinema tickets or a Rakuten Movie download, the choice is yours.",
          },
        ],
      }
    },
    goodCausesFaq(): FaqAccordionContent {
      return {
        title: '',
        content: [
          {
            title: 'How are good causes chosen?',
            text: 'Good causes are chosen by our members and our values, ensuring they make a meaningful impact. We focus on supporting up-and-coming athletes and charities that uphold our values, like our recent partnership with the RWW, where we helped provide children in disadvantaged conditions with a special gift from Santa, spreading joy and warmth to those who need it most.',
          },
          {
            title: 'What charities do you work with?',
            text: 'We are working with the RWW to provide children, living in disadvantaged conditions, with a special gift from Santa, spreading joy and warmth to those who need it most.',
          },
          {
            title: 'Can I submit charities or good causes to be considered?',
            text: `Of course you can, we are always open to hear about good causes, charities or athletes who need that little extra help to get where they need to be. You can let us know via our email address <a class='link' href='mailto:${this.BUSINESS_EMAIL}' target='_blank'>${this.BUSINESS_EMAIL}</a>.`,
          },
          {
            title: 'Why do you support good causes?',
            text: `At ${this.BUSINESS_NAME}, we believe in making a positive impact beyond the services we provide. Supporting good causes is at the heart of our mission to create opportunities and uplift communities. By partnering with charities that assist underprivileged children and nurture young athletes, we aim to help those who need it most achieve their potential.`,
          },
        ],
      }
    },
    prizesFaq(): FaqAccordionContent {
      return {
        title: '',
        content: [
          {
            title: 'How do I win these prizes?',
            text: `It's easy, you can enter by the following methods: <br>Free online entry: A one-time free entry for everyone. <br>Membership: Enjoy multiple entries, exclusive perks, and incredible rewards like dining discounts and free movie tickets—all starting at just ${i18n.global.n(11.99, 'currency')} a month.<br>Postal entry: Check out the terms and conditions for details on how to enter by post.<br>Each entry is of equal weight in the draw. Once you have entered, sit back and relax or enjoy one of our daily live streams!`,
          },
          {
            title: 'Is this the actual car you are giving away?',
            text: "The car shown is often the actual prize; however, this depends on the specific draw. In some cases, a similar car of equal or greater value and specification may be provided. Full details are outlined in the terms and conditions of each competition. Please refer to the specific draw's terms for clarity.",
          },
          {
            title: 'Am I automatically entered?',
            text: "If you have an active membership, your entries will automatically be applied to all live draws, so sit back and relax! Or join the live streams, we stream our winner reveals on a daily basis, the streams are always fun and quite often we give away an additional prize to the people watching the stream (just don't tell everyone!)",
          },
          {
            title: 'Can I take a cash alternative?',
            text: "We sometimes offer a cash alternative, however this depends largely on the type of the prize! You can always find out about this in the prize's terms & conditions.",
          },
        ],
      }
    },
    memberZoneFaq(): FaqAccordionContent {
      return {
        title: '',
        content: [
          {
            title: `What is ${this.BUSINESS_NAME}?`,
            text: `Well, since you've already signed up, you probably have an idea—but here's the lowdown anyway! ${this.BUSINESS_NAME} is your golden ticket to daily prize draws, where you could win everything from cash to cars (yes, shiny ones). On top of that, you'll enjoy monthly perks like cinema tickets, discounts on your favorite eats, and exclusive deals to make life a little sweeter.<br>Here's how it works: Every day, we automatically enter you into our prize draws—no effort required. The higher your membership tier, the more entries you get and the better the perks. So sit back, relax, and let us do the hard work while you enjoy the benefits.`,
          },
          {
            title: 'What types of prizes can I win?',
            text: "<li class='ml-8'>Prizes include everything from cash, shiny cars, and luxury vacations to the latest tech gadgets. Every day, we host a live stream to announce the winners, and we often go the extra mile by adding a little something special to enhance the winner's experience!. Check out the live streams <a class='link' target='_blank' href='/live-draws'>here</a>. </li>",
          },
          {
            title: `How do I get started with ${this.BUSINESS_NAME}?`,
            text: "Getting started is simple! Sign up, choose one of our three membership plans, and you'll automatically receive entries to every prize draw.",
          },
        ],
      }
    },
    liveStreamsFaq(): FaqAccordionContent {
      return {
        title: '',
        content: [
          {
            title: "How do I know if I've won a prize?",
            text: `At ${this.BUSINESS_NAME}, we believe in making our prize draws as fun and transparent as possible. Winners are announced daily during our live streams, which are an exciting part of our community experience. Even if you miss the live stream, you can still check the <a href='/live-draws' class='link'>Live Draws</a> section of our website to catch up on the results.<br>If you're a lucky winner, there's no need to worry about missing out—we'll reach out to you directly using the contact details you provided when signing up. Whether it's through email, phone, or another method, we'll ensure you're promptly informed about your win so you can claim your prize without delay.`,
          },
          {
            title: 'When and where can I watch the live draws?',
            text: "Our live draws are streamed every day to keep the excitement going! You can watch them directly on our website in the <a class='link' href='/live-draws'>Live Draws</a> section. These streams are a fantastic way to join in the anticipation, hear the winner announcements live, and even participate in special giveaways we sometimes offer to viewers during the broadcasts.<br>If you can't tune in live, don't worry! All the draws are saved and available for replay, so you can watch them at your convenience. The live draws are designed to be engaging, so even if you're not a winner, you'll still enjoy the thrill of seeing others celebrate their incredible prizes.",
          },
          {
            title: 'How are the winners selected?',
            text: "To ensure complete fairness and transparency, we partner with <a class='link' target='_blank' href='//randomdraws.co.uk'>RandomDraws.co.uk</a>, a fully certified and independent platform. This platform is trusted across the industry for its ability to conduct impartial and secure prize draws.<br><br>Here's how it works: the draw takes place before the live stream to ensure everything is ready for the announcement. Each entry, whether it's from a free online submission, a postal entry, or a membership, is given equal weight, meaning everyone has a fair chance to win. Our draw process complies with the highest standards, including the Promotional Marketing Rule 8.24 of the CAP Code, guaranteeing an ethical and transparent process every single day.",
          },
          {
            title: 'Am I automatically entered into the draws?',
            text: `Yes! One of the best parts about being a ${this.BUSINESS_NAME} member is that your entries are automatically submitted to all daily prize draws. Depending on the membership tier you choose, you'll receive a set number of daily entries, increasing your chances of winning incredible prizes like cash, luxury vacations, cars, and more.<br>Once you've signed up and your membership is active, you don't have to lift a finger—just sit back, relax, and enjoy the excitement of knowing you're part of every single draw. If you're not a member yet, don't worry; you can still enter individual draws for free online or through our postal entry option.`,
          },
          {
            title: "Can I win if I don't watch the live stream?",
            text: "Absolutely! While we'd love for you to join our live streams and share in the excitement of the winner announcements, it's not a requirement to claim your prize. If you win, our team will contact you directly using the details you've provided.<br>We understand that life gets busy, and you may not always be able to tune in to watch the draw live. That's why we make sure all winners are notified personally so that nobody misses out on their prizes.",
          },
        ],
      }
    },
    HowItWorksSection(): FaqAccordionContent {
      return {
        title: 'How does it work?',
        content: [
          {
            title: `What is ${this.BUSINESS_NAME}?`,
            code: FaqCategories.HowItWorks,
            text: `${this.BUSINESS_NAME} is your all-access pass to daily fun and exclusive perks! It's an entertainment-focused membership that automatically enters you into daily prize draws, where you could win incredible rewards like cash, shiny cars, luxury trips, and more.<br>But that's not all—our members also enjoy a range of monthly perks, from free cinema tickets to discounts at top brands and restaurants. The best part? Each membership tier offers its own unique mix of daily entries and benefits, so there's something for everyone.<br>Curious to know more? Check out all the details on our Membership Page and see what you could be a part of! <br> You can find out more detail on our membership page, just visit <a class='link' target='_blank' href='/memberships'>www.goodlifeplus.co.uk/memberships</a>`,
          },
          {
            title: 'How do I know if I have won?',
            code: FaqCategories.HowItWorks,
            text: "Winners are announced daily during our live streams. Tune in live or visit the <a href='/live-draws' class='link'>Live Draws</a> section of our website to catch up on any missed draws.<br>If you win, we'll contact you directly via the details you've provided. Don't worry - we'll always make sure you know about your win!",
          },
          {
            title: 'How can I cancel my subscription?',
            code: FaqCategories.HowItWorks,
            text: `Easily! You can cancel your ${this.BUSINESS_NAME} membership at any time. Simply call us on <a href="tel:02046009030" class="link" target="_blank">0204 600 9030</a> during our opening hours of 9.00am to 5.30pm Monday to Thursday and Friday 9.00am to 5.00pm. Please rest assured that our team will always get back to you and resolve whatever issue you may have.`,
          },
          {
            title: 'How do I enter the draws?',
            code: FaqCategories.HowItWorks,
            text: `There are three simple ways to enter a draw:<br><br><strong>Free online entry</strong><br>A one-time free entry for everyone. <br><br><strong>Membership</strong><br>Enjoy multiple entries, exclusive perks, and incredible rewards like dining discounts and free movie tickets — all starting at just ${i18n.global.n(11.99, 'currency')} a month.<br><br><strong>Postal entry</strong><br>Check out the terms and conditions for details on how to enter by post. Once entered, sit back and relax or tune into our live streams to see if you've won!`,
          },
        ],
      }
    },
    whoAreWeSection(): FaqAccordionContent {
      return {
        title: `Who are ${this.BUSINESS_NAME}?`,
        content: [
          {
            title: `What is ${this.BUSINESS_NAME}?`,
            code: FaqCategories.WhoAreWe,
            text: `${this.BUSINESS_NAME} is your all-access pass to daily fun and exclusive perks! It's an entertainment-focused membership that automatically enters you into daily prize draws, where you could win incredible rewards like cash, shiny cars, luxury trips, and more.<br>But that's not all—our members also enjoy a range of monthly perks, from free cinema tickets to discounts at top brands and restaurants. The best part? Each membership tier offers its own unique mix of daily entries and benefits, so there's something for everyone.<br>Curious to know more? Check out all the details on our Membership Page and see what you could be a part of! <br> You can find out more detail on our membership page, just visit <a class='link' target='_blank' href='/memberships'>www.goodlifeplus.co.uk/memberships</a>`,
          },
          {
            title: 'How are good causes chosen?',
            code: FaqCategories.WhoAreWe,
            text: 'Good causes are chosen by our members and our values, ensuring they make a meaningful impact. We focus on supporting up-and-coming athletes and charities that uphold our values, like our recent partnership with the RWW, where we helped provide children in disadvantaged conditions with a special gift from Santa, spreading joy and warmth to those who need it most.',
          },
          {
            title: 'What charities do you work with?',
            code: FaqCategories.WhoAreWe,
            text: 'We are working with the RWW to provide children, living in disadvantaged conditions, with a special gift from Santa, spreading joy and warmth to those who need it most.',
          },
          {
            title: 'Can I submit charities or good causes to be considered?',
            code: FaqCategories.WhoAreWe,
            text: `Of course you can, we are always open to hear about good causes, charities or athletes who need that little extra help to get where they need to be. You can let us know via our email address <a class='link' href='mailto:${this.BUSINESS_EMAIL}' target='_blank'>${this.BUSINESS_EMAIL}</a> or via our phone number <a href="tel:02031461890" class="link" target="_blank">0203 146 1890</a>.`,
          },
          {
            title: 'Why do you support good causes?',
            code: FaqCategories.WhoAreWe,
            text: `At ${this.BUSINESS_NAME}, we believe in making a positive impact beyond the services we provide. Supporting good causes is at the heart of our mission to create opportunities and uplift communities. By partnering with charities that assist underprivileged children and nurture young athletes, we aim to help those who need it most achieve their potential.`,
          },
          {
            title: 'Where are you based?',
            code: FaqCategories.WhoAreWe,
            text: `We are London based, Our dedicated team is here to support you every step of the way. Whether you have questions about our services or need assistance, you can count on us to provide prompt and helpful responses. <br>To find out more you can contact us via <a class='link' href='mailto:${this.BUSINESS_EMAIL}' target='_blank'>${this.BUSINESS_EMAIL}</a> or give our friendly team a call on <a href="tel:02031461890" class="link" target="_blank">0203 146 1890</a>.`,
          },
        ],
      }
    },
    winnersAndPrizesSection(): FaqAccordionContent {
      return {
        title: 'Winners and prizes',
        content: [
          {
            title: 'How do I enter the draws?',
            code: FaqCategories.WinnersAndPrizes,
            text: `There are three simple ways to enter a draw:<br><br><strong>Free online entry</strong><br>A one-time free entry for everyone. <br><br><strong>Membership</strong><br>Enjoy multiple entries, exclusive perks, and incredible rewards like dining discounts and free movie tickets—all starting at just ${i18n.global.n(11.99, 'currency')} a month.<br><br><strong>Postal entry</strong><br>Check out the terms and conditions for details on how to enter by post. Once entered, sit back and relax or tune into our live streams to see if you've won!`,
          },
          {
            title: 'What types of prizes can I win?',
            code: FaqCategories.WinnersAndPrizes,
            text: `Prizes include everything from cash, shiny cars, and luxury vacations to the latest tech gadgets. Every day, we host a live stream to announce the winners, and we often go the extra mile by adding a little something special to enhance the winner's experience!. Check out the live streams <a class='link' target='_blank' href='${this.$router.resolve({ name: 'live-draws' }).href}'>here</a>.`,
          },
          {
            title: 'How do I know if I have won?',
            code: FaqCategories.WinnersAndPrizes,
            text: `Winners are announced daily during our live streams. Tune in live or visit the <a href='${this.$router.resolve({ name: 'live-draws' }).href}' class='link'>Live Draws</a> section of our website to catch up on any missed draws.If you win, we'll contact you directly via the details you've provided. Don't worry - we'll always make sure you know about your win!<br>How are the winners chosen? <br>We use <a class='link' target='_blank' href='//randomdraws.co.uk'>RandomDraws.co.uk</a>, a fully certified and independent platform, to select our winners. This guarantees 100% fairness and transparency.<br>Winners are chosen randomly every day, and the results are announced during our live streams. To keep things fair and compliant, the draw always takes place before the live stream, so we're all set to present the winner on air.<br>Our process meets the highest standards, including the Promotional Marketing Rule 8.24 of the CAP Code—because fairness is non-negotiable!`,
          },
        ],
      }
    },
    entriesAndTicketsSection(): FaqAccordionContent {
      return {
        title: 'Entries & tickets',
        content: [
          {
            title: 'How do I enter the draws?',
            code: FaqCategories.EntriesAndTickets,
            text: `There are three simple ways to enter a draw:<br><br><strong>Free online entry</strong><br>A one-time free entry for everyone. <br><br><strong>Membership</strong><br>Enjoy multiple entries, exclusive perks, and incredible rewards like dining discounts and free movie tickets—all starting at just ${i18n.global.n(11.99, 'currency')} a month.<br><br><strong>Postal entry</strong><br>Check out the terms and conditions for details on how to enter by post. Once entered, sit back and relax or tune into our live streams to see if you've won!`,
          },
        ],
      }
    },
    faqPageCategories(): { emoji: string; title: string; slug: string }[] {
      return [
        {
          emoji: '✅',
          title: 'Getting Started',
          slug: FaqSlugs.GettingStarted,
        },
        {
          emoji: '🏎️',
          title: 'Prizes & Draws',
          slug: FaqSlugs.PrizesDraws,
        },
        {
          emoji: '🏆',
          title: 'Live Streams & Winners',
          slug: FaqSlugs.LiveStreamsWinners,
        },
        {
          emoji: '🍕',
          title: 'Membership Benefits',
          slug: FaqSlugs.MembershipBenefits,
        },
        {
          emoji: '🧾',
          title: 'Payments & Billing',
          slug: FaqSlugs.PaymentBilling,
        },
        // {
        //   emoji: '⚙️',
        //   title: 'Account & Billing Management',
        //   slug: FaqSlugs.AccountBillingManagement,
        // },
        {
          emoji: '📞',
          title: 'Troubleshooting & Support',
          slug: FaqSlugs.TroubleshootingSupport,
        },
      ]
    },
    faqPageContent(): {
      title: string
      code: string
      content: { title: string; text: string }[]
    }[] {
      return [
        {
          title: '✅ Getting Started',
          code: FaqSlugs.GettingStarted,
          content: [
            {
              title: `What is ${this.BUSINESS_NAME} and how does it work?`,
              text: `${this.BUSINESS_NAME} is a membership that gives you automatic entries into daily prize draws for luxury cars, holidays, and cash prizes. Plus, you get exclusive discounts, freebies, and perks every month.`,
            },
            {
              title: 'How do I enter the prize draws?',
              text: `Every ${this.BUSINESS_NAME} member is automatically entered into 30+ prize draws each month. You can also enter for free online or by post, check our terms for details: <a
                href="${this.$toFullUrl('instant-win-terms-and-conditions')}"
                class="link"
                target="_blank"
              >${this.$toFullUrl('instant-win-terms-and-conditions')}</a>.`,
            },
            {
              title: `Is ${this.BUSINESS_NAME} really free to enter?`,
              text: 'Yes! You can enter each prize draw for free online or via post. However, becoming a member gives you multiple automatic entries and unlocks exclusive perks.',
            },
            {
              title: "What's included in my membership?",
              text: 'Your membership includes:<br /><ul class="list-disc"><li class="ml-8">Automatic prize draw entries (number varies by plan).</li><li class="ml-8">Dining and entertainment discounts with Tastecard.</li><li class="ml-8">Freebies like coffee, meal deals, and shopping vouchers.</li><li class="ml-8">VIP perks (for higher-tier members), including free cinema tickets.</li></ul>',
            },
            {
              title: 'How does the free trial work?',
              text: "New members can enjoy a 7-day free trial (for monthly plans) or a 14-day money-back guarantee (for annual plans). Cancel anytime within this period if it's not for you.",
            },
            {
              title: 'Can I cancel my membership at any time?',
              text: 'Yes, you can cancel anytime by calling our support team, no hidden fees or contracts.',
            },
          ],
        },
        {
          title: '🏎️ Prizes & Draws',
          code: FaqSlugs.PrizesDraws,
          content: [
            {
              title: 'What prizes can I win?',
              text: 'Luxury cars, dream holidays, tech gadgets, cash prizes, and more! We run daily prize draws. Check our homepage for upcoming prizes.',
            },
            {
              title: 'How are winners chosen?',
              text: 'We use <a class="link" href="//randomdraws.co.uk" target="_blank">RandomDraws.co.uk</a>, an independent and certified draw system, to ensure complete fairness and transparency.',
            },
            {
              title: 'When are the livesteams held?',
              text: 'Winners are announced happen Monday to Friday at 7PM and 12PM on weekends and are streamed live on our website and social media.',
            },
            {
              title: 'Can I watch past draws?',
              text: 'Yes! All winner annoucements from the last 30 days are available in the live draws section of our website.',
            },
          ],
        },
        {
          title: '🏆 Prize Draws and Winners',
          code: FaqSlugs.LiveStreamsWinners,
          content: [
            {
              title: "How do I know if I've won?",
              text: "Winners are announced daily via live stream. If you win, you'll also get an email and SMS notification.",
            },
            {
              title: 'Where can I watch the live draws?',
              text: 'You can watch live and past draws on our website or social media channels.',
            },
            {
              title: 'What happens if I miss a draw?',
              text: "Don't worry, one of our very own presenters will call you personally. If you miss the call you'll get an email, and you can check past winners on our website.",
            },
            {
              title: 'Can I swap my prize for cash?',
              text: "Some prizes offer a cash alternative. If available, you'll be given the option when you win.",
            },
          ],
        },
        {
          title: '🍕 Membership Benefits',
          code: FaqSlugs.MembershipBenefits,
          content: [
            {
              title: 'What discounts and perks do I get?',
              text: 'As a member, you get:<br /><ul class="list-disc"><li class="ml-8">2-for-1 dining and discounts with Tastecard</li><li class="ml-8">Monthly freebies like coffee, Greggs meal deals and shopping vouchers</li><li class="ml-8">VIP benefits (for Platinum & VIP members), including free cinema tickets</li><ul>',
            },
            {
              title: 'How do I use my benefits?',
              text: `Visit your <a class="link" href="${this.$router.resolve({ name: 'benefits' }).href}">benefits page</a> to find and redeem your discounts, vouchers, and free treats.`,
            },
            {
              title: 'What is a Tastecard, and how does it work?',
              text: `Tastecard gives you 2-for-1 meals and up to 50% off at thousands of restaurants. Your digital Tastecard is available in the <a class="link" href="${this.$router.resolve({ name: 'members-zone' }).href}">Members Zone</a>.`,
            },
            {
              title: "What's included in the VIP membership?",
              text: 'VIP members get more prize draw entries, plus premium perks like Vue cinema tickets or a Rakuten movie download every month.',
            },
            {
              title: 'Can I upgrade or downgrade my plan?',
              text: `You can upgrade anytime via the banner in your <a class="link" href="${this.$router.resolve({ name: 'members-zone' }).href}">Members Zone</a>. To downgrade, just give us a call and our team will sort it for you.`,
            },
          ],
        },
        {
          title: '🧾 Payments & Billing',
          code: FaqSlugs.PaymentBilling,
          content: [
            {
              title: `How much does ${this.BUSINESS_NAME} cost?`,
              text: `Plans start from £11.99/month, with higher-tier memberships offering more entries and perks. Check our plan page for details: <a class='link' href='${this.$router.resolve({ name: 'memberships' }).href}' target='_blank'>www.goodlifeplus.co.uk/memberships</a>`,
            },
            {
              title: 'When will I be charged?',
              text: 'Billing occurs monthly or annually based on your plan. Your renewal date is in your account settings.',
            },
            {
              title: 'What payment methods do you accept?',
              text: 'We accept Visa, and Mastercard.',
            },
            {
              title: 'How do I cancel my membership?',
              text: 'Cancel anytime by calling our support team.',
            },
            {
              title: 'Do you offer refunds?',
              text: 'Yes! We offer a 14-day money-back guarantee for annual plans. Monthly plans do not include refunds once billed.',
            },
            {
              title: 'How do I withdraw money from my account?',
              text: `If you've won cash, you can request a withdrawal right from the Member Zone:<br /><ul class="list-disc"><li class="ml-8">Enter the amount you'd like to withdraw.</li><li class='ml-8'>Click Request withdrawal.</li><li class='ml-8'>A member of our team will be in touch within 48 hours to arrange payment.</li><li class='ml-8'>Sit tight, your funds are on the way.</li></ul><br>Withdrawals must be at least £10 and can only be made from your cash balance (not bonus credit).`,
            },
          ],
        },
        {
          title: '⚙️ Account & Billing Management',
          code: FaqSlugs.AccountBillingManagement,
          content: [
            {
              title: 'How do I update my payment details?',
              text: 'Log in to your account, go to Billing, and update your payment info.',
            },
            {
              title: 'Where can I find my invoices?',
              text: 'Invoices and payment history are available in the Billing section of your account.',
            },
            {
              title: 'How do I change my membership plan?',
              text: 'You can upgrade or downgrade in account settings.',
            },
            {
              title: 'How do I cancel my membership?',
              text: 'Cancel anytime via account settings.',
            },
          ],
        },
        {
          title: '📞 Troubleshooting & Support',
          code: FaqSlugs.TroubleshootingSupport,
          content: [
            {
              title: "I can't log into my account - what should I do?",
              text: 'Try resetting your password. If the issue persists, contact customer support.',
            },
            {
              title: "My discount code isn't working - how can I fix it?",
              text: "Check the expiry date and terms. If it's still not working, reach out to support.",
            },
            {
              title: "I haven't received my monthly treat - what should I do?",
              text: 'Check your benefits page, it should be available there. If not, contact support.',
            },
            {
              title: 'How do I contact customer support?',
              text: `Visit the Help Centre on our website or email <a class="link" href="mailto:${this.BUSINESS_EMAIL}">${this.BUSINESS_EMAIL}</a>`,
            },
          ],
        },
      ]
    },
  },

  actions: {
    prizeSpecificFaq(prize: Prize): FaqAccordionContent {
      return {
        title: '',
        content: [
          {
            title: 'How do I win these prizes?',
            text: `It's easy, you can enter by the following methods: <br>Free online entry: A one-time free entry for everyone. <br>Membership: Enjoy multiple entries, exclusive perks, and incredible rewards like dining discounts and free movie tickets—all starting at just ${i18n.global.n(11.99, 'currency')} a month.<br>Postal entry: Check out the terms and conditions for details on how to enter by post.<br>Each entry is of equal weight in the draw. <br>Once you have entered, sit back and relax or enjoy one of our daily live streams!`,
          },
          {
            title: `Is this the actual ${prize.categories.includes(PrizeCategories.DreamCars) ? 'car' : 'item'} you are giving away?`,
            text: `The ${prize.categories.includes(PrizeCategories.DreamCars) ? 'car' : 'item'} shown is often the actual prize; however, this depends on the specific draw. In some cases, a similar ${prize.categories.includes(PrizeCategories.DreamCars) ? 'car' : 'item'} of equal or greater value and specification may be provided. Full details are outlined in the terms and conditions of each competition. Please refer to the specific draw's terms for clarity.`,
          },
          {
            title: 'Am I automatically entered?',
            text: "If you have an active membership, your entries will automatically be applied to all live draws, so sit back and relax! Or join the live streams, we stream our winner reveals on a daily basis, the streams are always fun and quite often we give away an additional prize to the people watching the stream (just don't tell everyone!)",
          },
          {
            title: 'Can I take a cash alternative?',
            text: "We sometimes offer a cash alternative, however this depends largely on the type of the prize! You can always find out about this in the prize's terms & conditions.",
          },
          {
            title: 'Is there a free trial period?',
            text: 'Our monthly plans all come with a 7 days free trial period, and our annual plans have a 14 day money back guarantee. Making our membership absolutely worry free!',
          },
        ],
      }
    },
    instantWinsFaq(instantWinDraw: InstantWinDraw): FaqAccordionContent {
      return {
        title: i18n.global.t('section.faqs.heading'),
        content: [
          {
            title: `What happens if a draw doesn't sell out?`,
            text: 'We will always honour the entries and perform the end prize draw shortly after the close date. On the following Monday we will announce the winner of the end draw prize in our live stream.',
          },
          {
            title: 'What are Instant Wins?',
            text: `Instant Wins are draws that give you not only the chance to win the closing end prize, but also to win cash and other prizes instantly! This is a great way to get a bit of entertainment in alongside your ${this.BUSINESS_NAME} subscription.`,
          },
          {
            title: 'Can I win more than one prize?',
            text: 'Absolutely, you have every chance of winning more than one prize, even if you buy a single entry you can win twice, you can win an instant win prize and the end prize!',
          },
          {
            title: 'If I win how long will it take to get my prize?',
            text: `
              Most of the prizes are delivered instantly, you don't need to lift a finger!
              <br>
              <br>
              If you're a lucky person and win a cash prize, you will just need to email us at <a
                    class="link"
                    href="mailto:instantwin@goodlifeplus.co.uk"
                    target="_blank"
                    >instantwin@goodlifeplus.co.uk</a
                  > we will be in touch as soon as possible!
              <br>
              <br>
              Entries you have won to future prize draws will automatically be added to your account and you can see them in the Members-zone. All you have to do to check them is set a password after your purchase to log in, or click the link in the email we send you to set your password and access the Members-zone to see your entries into future prize draws!
              <br>
              <br>
              Should you win the end prize draw, we'll be in touch within 5 working days.
            `,
          },
          {
            title: `I have a question that isn't answered here! Help?`,
            text: `You can always reach out to our lovely customer service team, they can be reached via email on <a
                    class="link"
                    href="mailto:instantwin@goodlifeplus.co.uk"
                    target="_blank"
                    >instantwin@goodlifeplus.co.uk</a
                  >`,
          },
          {
            title: 'Competition Rules',
            text: `
              You will be allocated ticket number(s) at random when your order is
              confirmed, when you will be able to see your ticket number(s) and
              instant win results. Your purchased ticket numbers will also be
              available in the Members-zone. The total amount of entries for this
              competition is ${i18n.global.n(instantWinDraw.total_tickets, 'number')}. The end draw will take place on the
              ${i18n.global.d(instantWinDraw.main_prize.close_at.toJSDate(), 'long')}
              regardless of the number of tickets sold in this instant win
              competition.
            <br>
            <br>
            <div>
              To enter the end draw for free send an unenclosed postcard stating
              your: Full Name, Email Address, Phone Number, Full Address, Draw Name.
              All entries must be sent to
              <address class="inline">
                ${this.BUSINESS_REGISTERED_NAME}, ${this.BUSINESS_ADDRESS}
              </address>
              , entries must be received 24 hours before the draw close date.
            </div>
            <br>
            <br>
              Please ensure you have read and agree to our terms and conditions
              before entering by post or online. If your entries do not comply with
              the terms they will not be included in the draw. Please note, when
              making a purchase and if you don't have an account with
              ${this.BUSINESS_NAME}, an account will be created for you automatically,
              when this happens you will have the option to set a password right
              away or via the email we'll send you.
            <br>
            <br>
              You can find us on
              <a
                href="//www.facebook.com/GoodLifePlusUK/?locale=en_GB"
                class="link"
                target="_blank"
                >Facebook</a
              >
              and
              <a
                href="//www.instagram.com/joingoodlifeplus/"
                class="link"
                target="_blank"
                >Instagram</a
              >
              to stay up to date with the latest about ${this.BUSINESS_NAME}!
            <br>
            <br>
              You can find the full terms & conditions for instant win competitions
              at
              <a
                href="${this.$toFullUrl('instant-win-terms-and-conditions')}"
                class="link"
                target="_blank"
              >${instantWinDraw.title} Terms & Conditions</a>.
          `,
          },
          {
            title: 'When do I get my ticket number?',
            text: 'Your ticket number(s) will be shown after the checkout process as part of your order and will be available in your Members-zone!',
          },
          {
            title: `How will I know if I've won?`,
            text: 'You will be able to reveal your instant win results instantly after you have completed your order. You will be contacted directly if you win the end prize.',
          },
          {
            title: 'What happens if I win entries?',
            text: 'Entries you have won to future prize draws will automatically be added to your account and you can see them in the Members-zone. All you have to do to check them is set a password after your purchase to log in, or click the link in the email we send you! <br><br>Your entries will automatically be applied to the specific draw!',
          },
        ],
      }
    },
  },
})
