<script lang="ts">
import { useTailwind } from '@/plugins/useTailwind'
import type { Image } from '@/types'
import { defineComponent } from 'vue'

/**
 * AppSlideshow
 */
export default defineComponent({
  props: {
    /**
     * An ID to make each slideshow unique.
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * Class to apply to main images.
     */
    imageClass: {
      type: String,
      required: true,
    },
    /**
     * Class to apply to thumbnail images.
     */
    thumbnailClass: {
      type: String,
      required: true,
    },
    images: {
      type: Array<Image>,
      required: true,
    },
  },
  setup() {
    const tailwind = useTailwind()

    return {
      tailwindBreakpoints: tailwind.breakpoints,
    }
  },
})
</script>

<template>
  <div
    v-if="images.length"
    class="max-sm:full-bleed-mobile mb-0 flex flex-col-reverse"
  >
    <AppSlider
      v-if="images.length > 1"
      :slider-id="`${id}-slider-thumbnails`"
      :pagination="false"
      :navigation="false"
      :breakpoints="{
        0: {
          slidesPerView: 3,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
        },
      }"
    >
      <img
        v-for="(image, key) in images"
        :key
        :src="image.src"
        :alt="image.alt"
        loading="lazy"
        class="w-full overflow-y-hidden object-contain hover:cursor-pointer"
        :class="thumbnailClass"
      />
    </AppSlider>

    <AppSlider
      :slider-id="`${id}-slider`"
      :navigation="false"
      :pagination="false"
      :thumbnails="`#${id}-slider-thumbnails`"
      :breakpoints="{
        0: {
          slidesPerView: 'auto',
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
        },
      }"
      :class="{
        'py-0': images.length <= 1,
        'mb-4': images.length > 1,
      }"
    >
      <img
        v-for="(image, key) in images"
        :key
        :src="image.src"
        :alt="image.alt"
        loading="lazy"
        :class="imageClass"
      />
    </AppSlider>
  </div>
</template>
