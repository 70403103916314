<script lang="ts">
import { useCountdown } from '@/plugins/useCountdown'
import { useInstantWinsStore } from '@/stores/instant-wins'
import { usePrizesStore } from '@/stores/prizes'
import type { InstantWinDraw, InstantWinMainPrize, Nullable } from '@/types'
import { type PropType, defineComponent } from 'vue'

/**
 * A card to display a draw.
 *
 */
export default defineComponent({
  props: {
    prize: {
      type: Object as PropType<InstantWinMainPrize>,
      required: true,
    },
    progressPercentage: {
      type: Number as PropType<number | undefined>,
      required: false,
    },
    progressLoading: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      prizesStore: usePrizesStore(),
    }
  },
  data() {
    return {
      draw: null as Nullable<InstantWinDraw>,
      countdown: useCountdown(this.endCallback),
    }
  },
  computed: {
    penceTicketCost() {
      return Math.round((this.draw?.ticket_cost || 0) * 100)
    },
  },
  async mounted() {
    const instantWinsStore = useInstantWinsStore()
    this.draw = await instantWinsStore.getInstantWin(this.prize.code)
    this.countdown.start(this.prize.close_at)
  },
  beforeUnmount() {
    this.countdown.end()
  },
})
</script>

<template>
  <RouterLink
    v-if="draw"
    :to="{
      name: 'instant-win',
      params: { instantWinCode: draw.code },
    }"
    class="flex flex-col"
  >
    <img
      :src="draw.image.src"
      :alt="draw.image.alt"
      class="rounded-lg object-cover"
    />

    <div class="mt-2 flex flex-col">
      <AppProgress
        :name="prize.code"
        :value="progressPercentage || 0"
        :max="100"
        :show-progress-inline="true"
        :show-loading-inline="true"
        :is-loading="progressLoading || progressPercentage === undefined"
        progress-class="h-4 iw-progress-bar"
        progress-value-class="p-0.5"
        loading-class="p-0.5"
      />
      <div
        class="my-2 flex w-full items-center justify-between text-sm text-black"
      >
        <div class="font-bold">
          {{ draw?.title }}
        </div>
        <div class="flex">
          <div class="font-bold">
            {{ countdown.remainingDateTimeFormatted.days }}
          </div>
          d

          <div class="ml-1 font-bold">
            {{ countdown.remainingDateTimeFormatted.hours }}
          </div>
          h
          <div class="ml-1 font-bold">
            {{ countdown.remainingDateTimeFormatted.minutes }}
          </div>
          m
          <div class="ml-1 font-bold">
            {{ countdown.remainingDateTimeFormatted.seconds }}
          </div>
          s
        </div>
      </div>
    </div>
  </RouterLink>

  <div
    v-else
    class="flex items-center justify-center rounded-lg border border-slate-200 bg-slate-100"
  >
    <span
      class="m-8 inline-block h-8 w-8 animate-spin rounded-full border-2 border-primary border-t-transparent align-middle"
    />
  </div>
</template>

<style lang="scss">
// https://css-tricks.com/html5-progress-element/
.iw-progress-bar[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Get rid of default border in Firefox. */
  border: none;

  &::-webkit-progress-bar {
    @apply rounded-full !bg-slate-200;
  }

  &::-webkit-progress-value {
    @apply rounded-full bg-gradient-to-r from-orange-500 via-orange-300 to-white;
  }
}
</style>
