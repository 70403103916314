<script lang="ts">
import { useInstantWinsStore } from '@/stores/instant-wins'
import { usePrizesStore } from '@/stores/prizes'
import type { InstantWinDraw, InstantWinMainPrize, Nullable } from '@/types'
import { type PropType, defineComponent } from 'vue'

/**
 * A card to display a draw.
 *
 */
export default defineComponent({
  props: {
    prize: {
      type: Object as PropType<InstantWinMainPrize>,
      required: true,
    },
  },
  setup() {
    return {
      prizesStore: usePrizesStore(),
    }
  },
  data() {
    return {
      draw: null as Nullable<InstantWinDraw>,
    }
  },
  computed: {
    penceTicketCost() {
      return Math.round((this.draw?.ticket_cost || 0) * 100)
    },
  },
  async mounted() {
    const instantWinsStore = useInstantWinsStore()

    this.draw = await instantWinsStore.getInstantWin(this.prize.code)
  },
})
</script>

<template>
  <RouterLink
    v-if="draw"
    :to="{
      name: 'instant-win',
      params: { instantWinCode: draw.code },
    }"
    class="card prize-card relative flex h-auto flex-col bg-pure-white"
  >
    <p
      class="flex items-center justify-center rounded-t-lg border border-b-0 border-slate-300 bg-tertiary px-2 py-3 font-semibold uppercase text-pure-white md:px-4"
    >
      <AppIcon :icon="Icons.Lightening" class="mr-2" />
      Win Right Now!
    </p>

    <div class="relative border-x border-slate-300">
      <img
        :src="draw.main_prize.card_image.src"
        :alt="draw.main_prize.card_image.alt"
        class="aspect-square"
        width="340"
        height="240"
      />

      <div
        class="absolute right-2 top-2 rounded-100 bg-secondary px-4 py-1 font-bold"
      >
        {{ penceTicketCost }}p tickets
      </div>
    </div>

    <AppCountdown
      :datetime="prize.close_at"
      background="bg-black"
      class="border border-y-0 border-slate-300"
    />

    <div
      class="flex flex-grow flex-col justify-between gap-2 rounded-b-lg border border-slate-300 px-5 py-6"
    >
      <span>
        <p class="text-lg font-extrabold">
          {{ draw.title }}
        </p>

        <p class="h-line-2 line-clamp-2" :title="draw.description">
          {{ draw.description }}
        </p>
      </span>

      <AppButton
        :route="{
          name: 'instant-win',
          params: { instantWinCode: draw.code },
        }"
        class="button-secondary button-secondary-light not:last:mb-2 mt-4 min-w-full font-semibold"
        title="Play Now"
      >
        Play Now
      </AppButton>
    </div>
  </RouterLink>
</template>
