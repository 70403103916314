import {
  type RouteLocation,
  type RouteLocationNormalized,
  type RouteLocationNormalizedLoaded,
  type RouterScrollBehavior,
  createRouter,
  createWebHistory,
} from 'vue-router'

import cookiesGuard from './guards/cookies'
import fetchFeaturedCampaign from './guards/fetch-featured-campaign'
import fetchNextPrize from './guards/fetch-next-prize'
import { fetchPlans } from './guards/fetch-plans'
import fetchUserDetailsGuard from './guards/fetch-user'
import { endPageProgress, startPageProgress } from './guards/page-progress'
import setPageTitle from './guards/page-title'
import routes from './routes'

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL ?? '/'),

  // https://router.vuejs.org/guide/advanced/scroll-behavior
  scrollBehavior(
    to: RouteLocationNormalized,
    from: RouteLocationNormalizedLoaded,
    savedPosition: Parameters<RouterScrollBehavior>[2]
  ) {
    // always scroll to top
    return savedPosition
      ? savedPosition
      : new Promise((resolve) => {
          setTimeout(() => {
            resolve({ top: 0, behavior: 'smooth' })
          }, 250)
        })
  },
  linkActiveClass: 'active-link',
  routes,
})

// https://inside.365talents.com/blog/preload-pages-vue-router

router.beforeResolve((to, from) => {
  console.log('beforeResolve:', { to, from })
  startPageProgress()
  return true
})

router.beforeEach(async (to: RouteLocation, from: RouteLocation) => {
  console.log('beforeEach started:', { to, from })
  try {
    cookiesGuard()

    const results = await Promise.all([
      fetchUserDetailsGuard(),
      fetchFeaturedCampaign(to),
      fetchNextPrize(to),
      fetchPlans(),
    ])
    console.log('beforeEach completed:', results)

    return true
  } catch (error) {
    console.error('Navigation guard error:', error)
    return false
  }
})

router.afterEach((to: RouteLocation, from: RouteLocation) => {
  console.log('afterEach:', { to, from })
  setPageTitle(to, from)
  endPageProgress()
})

/**
 * Handles errors that occur during routing and navigation
 * when we deploy new versions of the app.
 */
router.onError((error, to) => {
  console.log('Router onError:', { error, to })
  const message = (error.message || '').toLowerCase()
  if (
    message.includes('loading chunk') ||
    message.includes('failed to load module script') ||
    message.includes('not a valid javascript mime type') ||
    message.includes('mime type of "text/html"') ||
    message.includes('failed to fetch dynamically imported module')
  ) {
    console.log('Current path:', window.location.pathname)
    console.log('Target path:', to.fullPath)
    window.location.assign(to.fullPath)
  }
})

if (import.meta.env.GLP_APP_ENV !== 'production') {
  router.addRoute({
    path: '/catalogue-debug',
    name: 'catalogue-debug',
    component: () => import('../views/CatalogueDebugView.vue'),
  })
}

export default router
